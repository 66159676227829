@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: poppins;
  src: url(./Fonts/poppins.ttf);
}
@font-face {
  font-family: clash;
  src: url(./Fonts/clash.ttf);
}


a{
  cursor: pointer;
  font-family: "clash";
}
.ant-tabs-nav-wrap, .ant-tabs-nav{
  border: none !important;
}
body {
  min-height: 100vh;
  margin: 0;
  color: white;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  background: linear-gradient(180deg, #000346 50%, #000 100%), linear-gradient(58.72deg, #0029FF 100%, #000346 70%), radial-gradient(100% 164.72% at 100% 100%, #0b1286 0%, #000 100%), radial-gradient(100% 148.07% at 0% 0%, #000 10%, #02074b 100%);
  background-blend-mode: overlay, overlay, difference, normal;
  background-repeat: no-repeat;
}

.background{
  background: linear-gradient(180deg, #000346 50%, #000 100%), linear-gradient(58.72deg, #0029FF 100%, #000346 70%), radial-gradient(100% 164.72% at 100% 100%, #0b1286 0%, #000 100%), radial-gradient(100% 148.07% at 0% 0%, #000 10%, #02074b 100%);
  background-blend-mode: overlay, overlay, difference, normal;
  background-repeat: no-repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-tabs-tab-btn{
  color: #fff !important;
}

.ant-pagination-item a, .ant-pagination-item button{
  color: #fff !important;
}
.ant-pagination-item-active{
  color: #000346 !important;
}

@keyframes underline {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.underline-animation {
  position: relative;
  display: inline-block;
}

.underline-animation::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 4px; /* Adjust the height of the underline as needed */
  background-color: #263BDC; /* Adjust the color of the underline as needed */
  animation: underline 0.5s ease-out forwards;
  transform-origin: bottom right;
}


.marquee-container {
  width: 100%; /* Adjust the width as needed */
  overflow: hidden;
}

.marquee-content {
  white-space: nowrap;
  animation: marquee 5s linear ; /* Adjust the animation duration as needed */
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
} 
