

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 20px;
  align-items: stretch;
}

.grid > article {
  border: 1px solid #ccc;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
  border: 2px solid #263BDC;
  backdrop-filter: blur(10px);
  border-radius: 12px;
}

.grid > article img {
  max-width: 100%;
}

.grid article {
  padding: 20px;
}
.icon-part{
  display: flex;
  align-items: center;
  gap: 20px;
}
.icon{
  width: 66px;
  height: auto;
}
.title{
  font-family: 'Clash Display';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.06em;
  color: #FFFFFF;
  margin: 0;
}
.text .desc{
  color: #BEBEBE;
  font-size: 13px;
  line-height: 20px;
  color: #BEBEBE;
  margin: 15px 0;
}
