.footer-wrapper{
  padding-top:120px;
}

.footer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
}

.logo,
.title1,
.title2,
.title3,
.title4,
.title5 {
  margin-top: 10px;
}

.title,
.social-icons {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .footer {
      grid-template-columns: 1fr;
  }
}
footer .menu-title{
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #FFFFFF;
}
footer p, footer li a{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #949494;
}
footer .social-icons a{
  transition: .3s;
}
footer .social-icons a:hover svg path{
  fill:#253BDC;
}
footer .social-icons svg path{
  transition: .3s;
  fill: #fff;
}
.footer-copyright {
  text-align: center;
  padding: 30px 0 10px 0;
  color: #fff;
  font-size: 14px;
}

@media (max-width: 768px) {
  .footer-copyright {
      font-size: 12px;
  }
}
.footer-icon ,
.menu-title{
  margin-bottom: 15px;
}
.social-icons a{
  margin-right: 15px;
}